const axios = require('axios').default;

//autocomplete with API

let input = Array.from(document.getElementsByClassName('autocomplete-form'));
let inputPosition = document.getElementById('checkbox-position');
let autocomplete = "";

function createBoxResults(input,box,response) {

    let parent = input.closest('.autocomplete-container');
    let autocompleteItems = Array.from(parent.getElementsByClassName('autocomplete-items'));

    let partnerlat = document.getElementById('partner-lat');
    let partnerlng = document.getElementById('partner-lng');

    autocompleteItems[0].innerHTML = "";

    let flags = [];

    //const department = ['02', '59', '60', '62', '80'];

    let i = 0
    response.data.features.forEach(function (element) {
        let context = element.properties.context;
        context = context.split(',');

        //if (department.includes(context[0])) {
            //  set input data-lat data-lng to the first result to search it if user directly tap "Enter"
            if(i == 0) {

                //input.setAttribute("data-lat", element.geometry.coordinates[1]);
                //input.setAttribute("data-lng", element.geometry.coordinates[0]);
                i++;
            }

            autocompleteItems[0].innerHTML += '' +
                '<div class="autocomplete-item" data-lat="' + element.geometry.coordinates[1] + '" ' +
                'data-lng="' + element.geometry.coordinates[0] + '" ' +
                'data-label="' + element.properties.label + '"' +
                'data-adresse="' + element.properties.name + '"' +
                'data-cp="' + element.properties.postcode + '"' +
                'data-city="' + element.properties.city + '"' +
                '>' +
                '' + element.properties.label + '</div>'
       // }
    });

    let autocomplete = Array.from(document.getElementsByClassName('autocomplete-item'));

    if (autocomplete != undefined) {
        autocomplete.forEach(function (element) {
            element.addEventListener('click', function (e) {
                let currentValue = this.getAttribute('data-label');
                let currentLat = this.getAttribute('data-lat');
                let currentLng = this.getAttribute('data-lng');
                let currentAdresse = this.getAttribute('data-adresse');
                let currentCP = this.getAttribute('data-cp');
                let currentCity = this.getAttribute('data-city');

                input.value = currentValue;
                //input.setAttribute("data-lat", currentLat);
                //input.setAttribute("data-lng", currentLng);
                partnerlat.value = currentLat;
                partnerlng.value = currentLng;

                if (box != null && box != '') {
                    box.classList.remove("autocomplete-active");
                }

                document.querySelector('.autocomplete-items').classList.remove('autocomplete-active');
input.closest('form').submit();
                //if form back user
                if (input.classList.contains('cp-autocomplete-back')) {
                    if (document.querySelector('input[name="adresse"]') != null) {
                        document.querySelector('input[name="adresse"]').value = currentAdresse;
                        document.querySelector('input[name="cp"]').value = currentCP;
                        document.querySelector('input[name="ville"]').value = currentCity;
                        document.querySelector('input[name="pays"]').value = 'France';
                        document.getElementById('lat').value = currentLat;
                        document.getElementById('lng').value = currentLng;

                        var event = new Event('change');
                        document.getElementById('lat').dispatchEvent(event);
                    }
                }
                //end form back user
            })
        })

        /* document.addEventListener('click', function () {
            console.log(box)
            if (box != null) {
                box.classList.remove("autocomplete-active");
                box = "";
            }
        }) */
    }
}

function loadCityBoxResults(input,inputPosition,e) {

    let box = "";

    if (input.value != "") {
        if (!document.getElementsByClassName('autocomplete-items').length) {
            box = document.createElement('div');
            box.setAttribute("class", "autocomplete-items autocomplete-cp");
            input.after(box);
            if (!box.classList.contains('autocomplete-active')) {
                box.classList.add("autocomplete-active");
            }
        }else{
            document.querySelector('.autocomplete-items').classList.add("autocomplete-active");
        }

        if (inputPosition != null) {
            inputPosition.checked = false;
        }

        axios({
            method: 'get',
            url: 'https://api-adresse.data.gouv.fr/search/?q=' + encodeURI(input.value),
        }).then(createBoxResults.bind(this,input,box));
    } else {
            document.getElementById('partner-lat').value = "";
            document.getElementById('partner-lng').value = "";
            document.querySelector('.autocomplete-items').classList.remove('autocomplete-active');

        if (box != null && box != '') {
            box.remove("autocomplete-active");
            box = "";
        }
    }
}

input.forEach(function (input) {
    input.addEventListener('keyup',loadCityBoxResults.bind(this,input,inputPosition));
})
//end autocomplete with API

function occurrences(string, subString, allowOverlapping) {

    string += "";
    subString += "";
    if (subString.length <= 0) return (string.length + 1);

    var n = 0,
        pos = 0,
        step = allowOverlapping ? 1 : subString.length;

    while (true) {
        pos = string.indexOf(subString, pos);
        if (pos >= 0) {
            ++n;
            pos += step;
        } else break;
    }
    return n;
}

jQuery(function ($) {
    //start autocomplete simple
    function autocomplete(inp, arr, valueCheck) {
        var currentFocus;

        //valueCheck[0] est utilisé comme valeur de recherche, les autres sont des infos supplémentaires affichées sur le form

        let attrId = inp.getAttribute('id');

        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            if (this.value == "") {
                this.setAttribute('data-id', '')
            }

            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("lat", this.lat + "autocomplete-list");
            a.setAttribute("lng", this.lng + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (occurrences(arr[i][valueCheck[0]].toUpperCase(), val.toUpperCase())) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");

                    /*make the matching letters bold:*/
                    b.innerHTML =  arr[i][valueCheck[0]] ;

                    if (attrId == 'product-autocomplete') {
                        b.innerHTML +=  ' - ' + arr[i]['parent-title'];
                    } else {
                        b.innerHTML +=  '. ' + arr[i][valueCheck[1]]
                    }

                    if (attrId == 'product-autocomplete') {
                        b.innerHTML += "<input type='hidden' value='" + arr[i][valueCheck[0]] + ' - ' + arr[i]['parent-title'] + "' data-id=" + arr[i]['ID'] + ">";
                    } else {
                        b.innerHTML += "<input type='hidden' value='" + arr[i][valueCheck[0]] + '. ' + arr[i][valueCheck[1]] + "' data-lat=" + arr[i]['lat'] + " data-lng=" + arr[i]['lng'] + ">";
                    }
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;

                        if (attrId == 'product-autocomplete') {
                            let attrIdResponse = this.getElementsByTagName("input")[0].getAttribute('data-id');

                            inp.setAttribute('data-id', attrIdResponse);
                        } else {
                            let attrLatResponse = this.getElementsByTagName("input")[0].getAttribute('data-lat');
                            let attrLngResponse = this.getElementsByTagName("input")[0].getAttribute('data-lng');

                            inp.setAttribute('data-lat', attrLatResponse);
                            inp.setAttribute('data-lng', attrLngResponse);
                        }

                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });

        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");

            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            //console.log(x)
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    if (typeof (document.getElementById("product-autocomplete")) != 'undefined' && document.getElementById("product-autocomplete") != null) {
        autocomplete(document.getElementById("product-autocomplete"), ajax_product, ['post_title', 'ID']);
    }
});

