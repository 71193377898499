window.addEventListener('DOMContentLoaded', function () {
    let width = window.innerWidth;

    const menu = width => {
        const templateHeaderDesktop = document.getElementById('header-desktop-template');
        const templateHeaderMobile = document.getElementById('header-mobile-template');

        let templateHeaderDesktopContent = templateHeaderDesktop.content.cloneNode(true)
        let templateHeaderMobileContent = templateHeaderMobile.content.cloneNode(true)

        //display desktop menu
        if (width >= 1170) {
            //remove previous header
            (document.getElementById('header-mobile')) && document.getElementById('header-mobile').remove();
            //insert desktop header
            (document.getElementById('header-desktop')) || templateHeaderDesktop.after(templateHeaderDesktopContent);

            return false;
        }

        //display mobile menu

        //remove previous header
        (document.getElementById('header-desktop')) && document.getElementById('header-desktop').remove();
        //insert mobile header
        (document.getElementById('header-mobile')) || templateHeaderMobile.after(templateHeaderMobileContent);
    }

    window.toggleSubmenu = event => {
        let checkbox = event.target;
        let titleSectionTop = document.getElementById('title-section-top');

        if (event.target.checked == true) {
            let parentLabel = checkbox.closest('.menu-item').querySelector('.open-submenu')

            titleSectionTop.innerHTML = parentLabel.textContent;
        } else {
            let subMenu = checkbox.closest('.sub-menu');
            if (subMenu) {
                //level > 1
                let parentLabel = subMenu.closest('.menu-item').querySelector('.open-submenu')
                titleSectionTop.innerHTML = parentLabel.textContent;
            } else {
                //level == 1
                titleSectionTop.innerHTML = " ";
            }
        }
    }

    window.toggleMenu = event => {
        const _this = event.target;

        if (_this.classList.contains('active')) {
            document.getElementById('header-mobile-nav').querySelectorAll('.menu-checkbox').forEach(function (item) {
                item.checked = false;
            })
            document.getElementById('header-mobile-nav').querySelectorAll('.menu-close').forEach(function (item) {
                item.classList.remove('active')
            })
        }

        _this.classList.toggle('active');

        setTimeout(() => {
            _this.classList.toggle('end-animation');
        }, 300)

        document.getElementById('header-mobile-nav').classList.toggle('active');
    }

    window.addEventListener('resize', () => {
        width = window.innerWidth;

        menu(width);
    })

    menu(width);


})