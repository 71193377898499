// Fonction d'initialisation de la carte
let markerClusters;
let latLon;
let latPosition = 0;
let lngPosition = 0;
let markers = [];
let macarte;


if ($('#map-home').length) {
    macarte = L.map('map-home').fitWorld();

    //latPosition = $('#map-home').attr('data-lat');
    //lngPosition = $('#map-home').attr('data-lng');
}

if ($('#map').length) {
    macarte = L.map('map').fitWorld();

    //latPosition = $('#map').attr('data-lat');
    //lngPosition = $('#map').attr('data-lng');

}

if ($('#map').length || $('#map-home').length) {
    //check if needed to make new position
    //pages listing
    if (latPosition == undefined && lngPosition == undefined) {
        if ($('.zoom-coor').length) {
            let newPosition = $('.zoom-coor').each(function () {
                if ($(this).attr('data-coor') != "") {
                    latPosition = $.parseJSON($(this).attr('data-coor')).lat;
                    lngPosition = $.parseJSON($(this).attr('data-coor')).lng;

                    return false;
                }
            })
        }
    }

    function initMap() {

        markerClusters = L.markerClusterGroup();
        L.control.scale().addTo(macarte);

        L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
            // Il est toujours bien de laisser le lien vers la source des données
            attribution: 'données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',

        }).addTo(macarte);

        macarte.on('locationfound', onLocationFound);
        macarte.on('locationerror', onLocationError);

        /* if (latPosition != undefined && lngPosition != undefined) {
            macarte.setView([latPosition, lngPosition], 12);
        } */

        //if ($('#map').length) {
            let DOMmarkers = $('.map-container').attr('data-coordonees');

            let icon = '';
            let marker = '';

            DOMmarkers = $.parseJSON(DOMmarkers);

            //add current position
            if ($('.marker-single').length != 0) {
                if ($('.marker-single').length != 1 && latPosition != "" && lngPosition != "") {
                    DOMmarkers.push({ "lat": latPosition, "lng": lngPosition, "icon": $('#markers .marker').attr('data-icon') });
                }
            }

            for (let i = 0; i < DOMmarkers.length; i++) {
                if (typeof DOMmarkers[i].icon !== null) {
                    icon = new L.Icon({ iconUrl: DOMmarkers[i].icon, iconSize: [35, 51], });
                    marker = L.marker([DOMmarkers[i].lat, DOMmarkers[i].lng], { icon: icon });
                } else {
                    marker = L.marker([DOMmarkers[i].lat, DOMmarkers[i].lng]);
                }

                marker.bindPopup(
                    '<div style="text-align:center;font-weight: 600;font-size: 18px;line-height: 1.1; margin:10px auto;">' + DOMmarkers[i].title + '</div>' +
                    '<div  style="text-align:center;line-height: 1.1;font-size:16px;margin:10px auto;">' + DOMmarkers[i].adresse + '</div>' +
                    '<div style="text-align:center;">' +
                    '<a href="' + DOMmarkers[i].permalink + '" style="padding: 8px 15px;width: 140px;color:#fff;" class="btn btn-orange">Contacter</a>' +
                    '</div>');

                markerClusters.addLayer(marker);
                markers.push(marker);
            }

            var group = new L.featureGroup(markers);
            macarte.fitBounds(group.getBounds().pad(0.5));
            macarte.addLayer(markerClusters);
            macarte.scrollWheelZoom.disable();
            macarte.setZoom(10);
            latLon = L.latLng(DOMmarkers[0].lat, DOMmarkers[0].lng);

            icon = new L.Icon({ iconUrl: DOMmarkers[0].icon, iconSize: [35, 51], });
            let content = '<div style="text-align:center;font-weight: 600;font-size: 18px;line-height: 1.1; margin:10px auto;">' + DOMmarkers[0].title + '</div>' +
                '<div  style="text-align:center;line-height: 1.1;font-size:16px;margin:10px auto;">' + DOMmarkers[0].adresse + '</div>' +
                '<div style="text-align:center;">' +
                '<a href="' + DOMmarkers[0].permalink + '" style="padding: 8px 15px;width: 140px;color:#fff" class="btn btn-orange">Contacter</a>' +
                '</div>';


            let modal = L.marker(latLon,{ icon: icon }).bindPopup(content).addTo(macarte);

            modal.openPopup();

            macarte.setView(latLon, 11, { animation: true });
        //}
    }

    //change position if geolocation activated
    if (latPosition != "" && lngPosition != "") {
        latLon = L.latLng(latPosition, lngPosition);
        macarte.setView(latLon, 11, { animation: true });
    }

    function onLocationFound(e) {
        var radius = e.accuracy;
        let position = [e.latlng.lat, e.latlng.lng];

        if ($('#map-home').length) {
            document.getElementById('checkbox-position-value').value = position;
        }

        L.circle(e.latlng, radius).addTo(macarte);
    }

    function onLocationError(e) {
        alert(e.message);
    }

    /*if ($('#map-home').length) {
        //on loading
        let target = $('#map-home').attr('data-coordonees');
        target = JSON.parse(target);
        target = target[0]

        macarte.closePopup();

        latLon = L.latLng(target.lat, target.lng);
        console.log(latLon);
        let content = '<div style="text-align:center;font-weight: 600;font-size: 18px;line-height: 1.1; margin:10px auto;">' + target.title + '</div>' +
            '<div  style="text-align:center;line-height: 1.1;font-size:16px;margin:10px auto;">' + target.adresse + '</div>' +
            '<div style="text-align:center;">' +
            '<a href="' + target.permalink + '" style="padding: 8px 15px;width: 140px;color:#fff" class="btn btn-orange">Contacter</a>' +
            '</div>';

        let icon = '';
        let modal = '';

        if (typeof target.icon !== null) {
            icon = new L.Icon({ iconUrl: target.icon, iconSize: [35, 51], });
            modal = L.marker(latLon, { icon: icon }).bindPopup(content).addTo(macarte);
        } else {
            modal = L.marker(latLon).bindPopup(content).addTo(macarte);
        }

        modal.openPopup();


        macarte.setView(latLon, 11, { animation: true });
    }*/

/*
    $('.zoom-coor').click(function (e) {
        e.preventDefault();

        macarte.closePopup();

        let target = $(this).attr('data-coor');
        target = $.parseJSON(target);

        latLon = L.latLng(target.lat, target.lng);
        let content = '<p style="text-align:center;font-weight: 600;font-size: 18px;line-height: 1.1;">' + target.title + '</p>' +
            '<p  style="text-align:center;color: #01c060;line-height: 1.1;">' + target.adresse + '</p>' +
            '<p style="text-align:center;">' +
            '<a href="' + target.permalink + '" style="padding: 8px 15px;width: 140px;" class="btn btn-second">Contacter</a>' +
            '</p>';

        let icon = '';
        let modal = '';
        if (typeof target.icon !== null) {
            icon = new L.Icon({ iconUrl: target.icon, iconSize: [35, 51], });
            modal = L.marker(latLon, { icon: icon }).bindPopup(content).addTo(macarte);
        } else {
            modal = L.marker(latLon).bindPopup(content).addTo(macarte);
        }

        modal.openPopup();


        macarte.setView(latLon, 11, { animation: true });
    });
*/

    initMap();
}