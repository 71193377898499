
window.addEventListener('DOMContentLoaded', function () {
    const toggleZone = () => {
        document.addEventListener('click', function (element) {
            const _this = element.target;

            if (_this.classList.contains('toggle-zone-editable')) {
                const toggleZoneTargetId = _this.getAttribute('data-target');
                if (!window.localStorage.getItem(toggleZoneTargetId)) {
                    window.localStorage.setItem(toggleZoneTargetId, _this.textContent);
                }

                const toggleZoneTargetContainer = document.getElementById(toggleZoneTargetId);

                if (!toggleZoneTargetContainer.classList.contains('open')) {
                    toggleZoneTargetContainer.classList.add('open')
                    if (window.localStorage.getItem(toggleZoneTargetId) != _this.textContent) {
                        window.localStorage.setItem(toggleZoneTargetId, _this.textContent);
                    }
                    if (_this.textContent.length) {
                        _this.innerHTML = 'Cacher le contenu';
                    };
                } else {
                    toggleZoneTargetContainer.classList.remove('open')
                    _this.innerHTML = window.localStorage.getItem(toggleZoneTargetId);
                }
            }
        })
    }

    toggleZone();
})