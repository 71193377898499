import './partials/bootstrap.min';
import './partials/uikit.min';
import './menu';
import './toggle';
import './map'
import './filter-map'
import css from '../scss/app.scss';

$(window).on('load', function () {
    var owl = $('[data-owl="slide"]');
    var $rtl = owl.data('ow-rtl');
    owl.each(function (index, el) {
        var $item = $(this).data('item-slide');
        var $item_991 = ($item == 1) ? 1 : 3;
        var $item_640 = ($item == 1) ? 1 : 3;
        $(this).owlCarousel({
            nav: false,
            dots: true,
            rtl: $rtl,
            items: $item,
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            responsive: {
                0: {
                    items: 1 // In this configuration 1 is enabled from 0px up to 479px screen size
                },

                480: {
                    items: 1, // from 480 to 677
                },
                640: {
                    items: $item_640, // from this breakpoint 678 to 959
                },

                991: {
                    items: $item_991, // from this breakpoint 960 to 1199

                },
                1199: {
                    items: $item,
                }
            }
        });
    });
    $('#gform_submit_button_12').addClass('wpcf7-form-control wpcf7-submit btn btn-orange');
    $('#gform_submit_button_13').addClass('wpcf7-form-control wpcf7-submit btn btn-orange');

    jQuery("#modal-contact").click(function () {
        if (jQuery(this).hasClass('depliage')) {
            jQuery(this).removeClass('depliage');
            jQuery('.first-partie', this).removeClass('bg-black');
        } else {
            jQuery('.first-partie', this).addClass('bg-black');
            jQuery(this).addClass('depliage');
            jQuery(".first-step").removeClass('d-none');
            jQuery(".second-step").addClass('d-none');
            jQuery(".third-step").addClass('d-none');
        }
    });
    jQuery("#step1").click(function () {
        jQuery(".second-step").removeClass('d-none');
        jQuery(".first-step").addClass('d-none');
    });
    jQuery("#step2").click(function () {
        jQuery(".third-step").removeClass('d-none');
        jQuery(".second-step").addClass('d-none');
    });
    jQuery("#back-first").click(function () {
        jQuery(".first-step").removeClass('d-none');
        jQuery(".second-step").addClass('d-none');
        jQuery(".third-step").addClass('d-none');
    });
    jQuery("#back-second").click(function () {
        jQuery(".first-step").addClass('d-none');
        jQuery(".second-step").removeClass('d-none');
        jQuery(".third-step").addClass('d-none');
    })
});